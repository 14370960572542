import React, { useCallback, useContext } from 'react'
import './Blog.scss'
import TagsList from "components/TagsList/TagsList"
import Markdown from "react-markdown"
import Divider from "components/Divider/Divider"
import {
    AlbumCountriesDataItem,
    AlbumCover,
    AlbumGenresDataItem,
    AlbumInstrumentsDataItem,
} from "model"
import { TTagName } from "types"
import { getFormatDate, getImageUrl } from "utils"
import Authors from "components/Authors/Authors"
import Button from "components/Button/Button"
import { getQuery } from "helpers"
import { Link, useLocation } from "react-router-dom"
import { useInfiniteQuery } from '@tanstack/react-query'
import { strapiAxios } from 'api'
import qs from 'qs'
import Loader from 'components/Loader/Loader'
import PageHeader from 'components/PageHeader/PageHeader'
import EmptyMessage from 'components/EmptyMessage/EmptyMessage'
import { useTranslation } from 'react-i18next'
import rehypeRaw from 'rehype-raw'

const Blog = () => {
    const location = useLocation()
    const { t, i18n } = useTranslation()

    const getAlbums = useCallback(async ({ pageParam = 0 }) => {
        const query = qs.stringify({
                locale: i18n.language.split("-")[0],
                sort: {
                    "prevPublishedAt": "desc",
                    "publishedAt": "desc"
                },
                ...getQuery(location.search, i18n.language.split("-")[0], true)
            }
        )
        const response = await strapiAxios.get(`/api/posts?populate=deep,3&pagination[page]=${pageParam}&pagination[pageSize]=12&${query}`)
        return response.data
    }, [i18n.language, location.search])

    const {
        data,
        isLoading,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage
    } = useInfiniteQuery({
        queryKey: ['posts', location.pathname, location.search, i18n.language],
        queryFn: getAlbums,
        initialPageParam: 0,
        refetchOnWindowFocus: false,
        getNextPageParam: (lastPage, pages) => {
            const currentPage: number = lastPage.meta.pagination.page
            if (currentPage < lastPage.meta.pagination.pageCount) {
                return currentPage + 1
            }
        },
        refetchOnMount: false
    })

    return (
        <div className={"blog"}>
            <PageHeader title={t("blogPageTitle")}/>
            <Divider margin={"0rem"}/>
            {
                isLoading ? <Loader/> :

                    <div className={"blog__list"}>
                        {
                            !data?.pages[0].data?.length
                                ?
                                <EmptyMessage/>
                                :
                                data.pages.map((page, pageIndex) => {
                                    const posts = page.data
                                    return (
                                        posts.map((postData: any, postIndex: number) => {

                                            if (!postData.attributes) {
                                                return null
                                            }
                                            const post = postData?.attributes

                                            const album = post?.albums?.data?.[0]?.attributes
                                            const tags: Record<TTagName, any[] | undefined> = {
                                                genres: album?.genres as AlbumGenresDataItem[],
                                                countries: album?.countries as AlbumCountriesDataItem[],
                                                instruments: album?.instruments as AlbumInstrumentsDataItem[],
                                                nationalities: album?.nationalities as AlbumInstrumentsDataItem[]
                                            }
                                            const publishedAt = post?.prevPublishedAt ?? post?.publishedAt
                                            const authors = post?.authors?.data
                                            const cover = album?.cover as AlbumCover
                                            const url = cover?.data?.attributes?.url
                                            const name = album?.name as string

                                            return (
                                                <>
                                                    <div className={"blog__item"}>
                                                        <Link
                                                            to={`/library/${post?.albums?.data?.[0]?.id}`}
                                                            className={"blog__cover"}
                                                        >
                                                            <img src={getImageUrl(url)} alt=""/>
                                                        </Link>
                                                        <div className={"preview"}>
                                                            <p className={'preview__name fontSize-s fontWeight-bold'}>{name}</p>
                                                            <Markdown
                                                                rehypePlugins={[rehypeRaw]}
                                                                className={"markdown--small preview__text"}
                                                            >
                                                                {post?.text}
                                                            </Markdown>
                                                            <Divider margin={".5rem"}/>
                                                            <Authors
                                                                authors={authors}
                                                                publishedAt={getFormatDate(publishedAt)}
                                                            />
                                                            <Divider margin={".5rem"}/>
                                                            {
                                                                Object.keys(tags)?.length ?
                                                                    <TagsList
                                                                        tags={tags}
                                                                        hideTitle={true}
                                                                        isPreview={true}
                                                                    />
                                                                    : null
                                                            }
                                                        </div>
                                                    </div>
                                                    <Divider margin={'1rem'}/>
                                                </>

                                            )
                                        })
                                    )
                                })
                        }
                    </div>
            }
            {
                data?.pages[0].data?.length && hasNextPage
                    ?
                    <div className={"library__actions"}>
                        <Button
                            variant={"tertiary"}
                            onClick={() => fetchNextPage()}
                            icon={{
                                name: 'plus',
                                position: "left"
                            }}
                            disabled={!hasNextPage || isFetchingNextPage}
                        >
                            {t("buttonDownloadMore")}
                        </Button>
                    </div>
                    :
                    null
            }
        </div>
    )
}

export default Blog